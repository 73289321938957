import {hasEventDetailsUrl, isEventRestricted} from '../../selectors/events'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {getComponentConfig} from '../../selectors/settings'
import {RsvpButton as RsvpButtonPresentation} from './rsvp-button'
import {ButtonOwnProps, ButtonStateProps} from './interfaces'

const mapRuntime = ({state, actions}: AppProps, {event}: ButtonOwnProps): ButtonStateProps => ({
  navigateToPage: () => actions.navigateToPage(event),
  hasUrl: hasEventDetailsUrl(state, event),
  settings: getComponentConfig(state).settings,
  eventRestricted: isEventRestricted(state, event),
})

export const RsvpButton = connect<ButtonOwnProps, ButtonStateProps>(mapRuntime)(RsvpButtonPresentation)

export * from './interfaces'
