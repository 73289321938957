import classNames from 'classnames'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {EventImage} from '../../event-image'
import {FullDateLocation} from '../../full-date-location'
import {LinkToPage} from '../../link-to-page'
import {Members} from '../../members'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {ShortDateLocation} from '../../short-date-location'
import {SocialBar} from '../../social-bar'
import {DynamicStyle} from '../../../../../../commons/components/dynamic-style'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import s from './card.scss'
import {CardProps} from '.'

const contentMarginTop = 24
const hoveredDateMargin = 30

export const Card = (props: CardProps) => {
  const [, forceUpdate] = useState({})
  const [shouldSetHeight, setShouldSetHeight] = useState(false)
  const {
    currentBreakpoint,
    allBreakpoints: {isListImageEnabled},
  } = useVisibilityStyles()
  const imageVisible = isListImageEnabled()
  const {expanded, event, showMembers, t, editor, compId, hasRibbon} = props

  const cardContainer = useRef<HTMLLIElement>()
  const card = useRef<HTMLDivElement>()
  const content = useRef<HTMLDivElement>()
  const bottom = useRef<HTMLDivElement>()
  const details = useRef<HTMLDivElement>()
  const topContainer = useRef<HTMLDivElement>()
  const title = useRef<HTMLDivElement>()
  const savedCardHeight = useRef(0)
  const timeout = useRef<NodeJS.Timer>()

  const getContentPadding = () => {
    const cardHeight = getCardHeight()
    const titleScrollHeight = title.current?.scrollHeight ?? 0
    const titleClientHeight = title.current?.clientHeight ?? 0
    const topHeight = topContainer.current?.clientHeight ?? 0
    const bottomHeight = bottom.current?.clientHeight ?? 0
    const detailsHeight = details.current?.clientHeight ?? 0
    const sum =
      contentMarginTop +
      detailsHeight +
      (topHeight - titleClientHeight + titleScrollHeight + hoveredDateMargin) +
      bottomHeight

    return (cardHeight - sum) / 2
  }

  const enableCardHover = () => {
    if (currentBreakpoint.isListAdditionalComponentsVisible()) {
      cardContainer.current.classList.add(s.hoverCard)
    } else {
      cardContainer.current.classList.remove(s.hoverCard)
    }
  }

  const onMouseEnter = useCallback(() => {
    enableCardHover()
    clearTimeout(timeout.current)
    setShouldSetHeight(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    content.current.scrollTop = 0
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      setShouldSetHeight(false)
    }, 1000)
  }, [])

  const getContainerClasses = () =>
    classNames(s.container, {
      [s.noMembers]: !showMembers,
      [s.hasRibbon]: hasRibbon,
    })

  const getCardHeight = () => {
    const cardHeight = card.current?.clientHeight ?? 0
    if (!expanded && cardHeight) {
      savedCardHeight.current = cardHeight
    }
    return savedCardHeight.current
  }

  useEffect(() => {
    if (editor && expanded) {
      enableCardHover()
    }
  }) // no dependencies to catch currentBreakpoint.isListAdditionalComponentsVisible() change

  useEffect(() => {
    if (!savedCardHeight.current) {
      forceUpdate({})
    }
    // there is font layout shift happening if Title fonts are big and title is a little longer.
    // Initial height is measured when title is one line long, and it then becomes two lines long.
    // This timeout catches height change after shift.
    setTimeout(() => {
      const prevCardHeight = savedCardHeight.current
      const cardHeight = getCardHeight()
      if (prevCardHeight !== cardHeight) {
        forceUpdate({})
      }
    }, 1000)
  }, [])

  const namespace = `${compId}-${event.id}-${s.card}`
  const cardHeight = getCardHeight()
  const cardHeightWithBorders = `calc((${cardHeight} + (var(--cardBorderWidth) * 2)) * 1px)`
  const contentPadding = getContentPadding()
  const detailsHeight = details.current?.clientHeight ?? 0
  return (
    <>
      <DynamicStyle namespace={namespace}>
        {`
            &.${s.hoverCard}:hover .${s.content},
            &.${s.hoverCard}.${s.expanded} .${s.content} {
              padding-top: ${contentPadding}px;
              overflow-y: ${contentPadding > 0 ? 'hidden' : 'scroll'};
            }

            &.${s.hoverCard}:hover,
            &.${s.hoverCard}.${s.expanded} {
              height: ${cardHeightWithBorders};
            }
            &.${s.hoverCard}:hover .${s.content} > .${s.cardContentItem},
            &.${s.hoverCard}.${s.expanded} .${s.content} > .${s.cardContentItem} {
              width: ${topContainer.current?.clientWidth}px
            }
            &.${s.hoverCard}:hover .${s.detailsContainer},
            &.${s.hoverCard}.${s.expanded} .${s.detailsContainer} {
              height: ${detailsHeight}px;
            }

            .${s.content} {
              margin-top: ${contentMarginTop}px;
            }
          `}
      </DynamicStyle>
      <li
        className={classNames(s.card, namespace, expanded ? s.expanded : null, editor && s.disableTransitions)}
        ref={cardContainer}
        data-hook={DH.card}
        style={{height: shouldSetHeight ? cardHeightWithBorders : undefined}}
      >
        <div className={getContainerClasses()} ref={card} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
          {imageVisible ? (
            <div className={classNames(s.imageContainer, s.image)}>
              <div className={classNames(s.imageInnerContainer, s.image)}>
                <EventImage event={event} opacityFallback backgroundFallback />
              </div>
            </div>
          ) : null}
          <div className={classNames(s.content, s.evCardContentColor)} ref={content} data-hook="content">
            <div className={s.cardContentItem} ref={topContainer}>
              {hasRibbon ? (
                <div className={s.ribbonContainer} data-hook="ribbon-container">
                  <Ribbon event={event} className={s.ribbon} />
                </div>
              ) : null}
              <div className={s.title} data-hook="title" ref={title}>
                <LinkToPage event={event}>{event.title}</LinkToPage>
              </div>
              <div className={classNames(s.evCardContentFont, s.shortDateLocation)} data-hook="short-date-location">
                <ShortDateLocation event={event} />
              </div>
              <div className={classNames(s.evCardContentFont, s.fullDateLocation)}>
                <FullDateLocation dataHook="full-date-location" event={event} />
              </div>
            </div>
            <div className={classNames(s.cardContentItem, s.detailsContainer)}>
              <div className={classNames(s.details, s.evCardContentFont)} data-hook="details" ref={details}>
                <div className={s.dateLocation}>
                  <FullDateLocation event={event} />
                </div>
                {event.description ? (
                  <div className={s.description} data-hook="description">
                    {event.description}
                  </div>
                ) : null}
                <div className={s.socialBar}>
                  <SocialBar t={t} event={event} />
                </div>
              </div>
            </div>
          </div>
          <div className={s.bottomContainer} ref={bottom}>
            {showMembers ? (
              <div className={s.members} data-hook="members">
                <Members event={event} />
              </div>
            ) : null}
            <div className={s.buttonSection} data-hook="button-section">
              <RsvpButton event={event} />
            </div>
          </div>
        </div>
      </li>
    </>
  )
}
